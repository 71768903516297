<template>
    <div v-show="pp">
        <div class="basic_pp bottom_up" :class="{'off':off, 'no_animation':no_animation}">
            <!-- <div class="dimm bg-transparent" @click="pp_off()"></div> -->
            <div class="content bg-gray-relative-100 shadow p-0"
                    @mousedown="StartDrag"
                    @touchstart="StartDrag"
                    :class="{
                             'dragging':isDragging,
                             'full_paging':fullPagIng, 
                             'full_paged':fullPaged,
                             'tiny_paging':tinyPagIng,
                             'tiny_paged':tinyPaged,
                             'overflow-hidden':tinyPaged,
                            }"
            >
                <div class="body overflow-auto" ref="basic_pp_body">
                    <div>
                        <div class="black_bg bg-img w-100 h-px-200" :style="`background-image: url(${items[item_id].img})`" v-show="fullPaged">
                            <div class="position-absolute flex-between-center w-100 top-0 start-0 p-2">
                                <div  @click="TinyPaged()" class="text-white px-2">
                                    <i class="fal fa-chevron-left"></i>
                                </div>
                                <div class="position-relative zindex-1 text-white bg-body-i bg-opacity-50 rounded-circle w-px-32 h-px-32 flex-center-center">
                                    <i class="fal fa-star" :class="{'fas text-warning' : items[item_id].bookmark}"  @click="items[item_id].bookmark = !items[item_id].bookmark"></i>
                                </div>
                            </div>
                        </div>
                        <div class="p-3 bg-body mb-2">
                        <!-- <div class="p-3 bg-body mb-2" :class="{'pt-1':tinyPaged}"> -->
                            <!-- <div class="text-center cursor-pointer text-gray-relative-500" @click="FullPaging()" v-show="tinyPaged">
                                <i class="fal fa-angle-up"></i>
                            </div> -->
                            <div class="d-flex align-items-center position-relative pb-4">
                                <div class="flex-grow-1 txt_box small w-80">
                                    <p class="h5 fw-bold mb-1">
                                        <span class="badge small text-body rounded-1 fw-light me-1 px-1 border border-gray-relative-200">{{items[item_id].type}}</span>
                                        {{items[item_id].title}}
                                    </p>
                                    <p class="lh-sm text-gray-relative-500 flex-start-center">
                                        <i class="fas fa-parking me-1 text-gray-relative-400"></i>총 {{items[item_id].lot}}면
                                    </p>
                                </div>
                                <div>
                                    <i class="fas fa-directions fa-3x text-main" @click="GotoDriving()"></i>
                                </div>
                            </div>
                            <div class="pb-4">
                                <div class="flex-between-center pb-2">
                                    <span class="text-gray-relative-600 small">주차요금</span>
                                    <span class="h5">
                                        <small class="text-gray-relative-500 me-2">{{items[item_id].sell[0].title}}</small>
                                        <span>{{items[item_id].sell[0].price.toLocaleString()}}원</span>
                                    </span>
                                </div>
                                <div class="flex-between-start">
                                    <span class="text-gray-relative-600 small">운영시간</span>
                                    <span class="h5 text-end">
                                        <small class="text-gray-relative-500 me-2">{{items[item_id].operating_time[0].time}}</small><span>{{items[item_id].operating_time[0].start_time}}</span> ~ <span>{{items[item_id].operating_time[0].end_time}}</span>
                                        <br>
                                        <small class="text-gray-relative-500 me-2">{{items[item_id].operating_time[2].time}}</small><span>{{items[item_id].operating_time[2].start_time}}</span> ~ <span>{{items[item_id].operating_time[2].end_time}}</span>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div class="bg-gray-relative-200 py-2 w-100 text-center rounded mb-2" v-show="!items[item_id].affiliated">
                                    <span class="small">앱결제를 지원하지 않는 주차장입니다</span>
                                </div>
                                <div class="mb-2" v-if="items[item_id].affiliated">
                                    <ul>
                                        <li class="p-2 rounded-3 mb-2 flex-between-center" :class="{'ticket_abled':sell_item.available, 'ticket_disabled':!sell_item.available}" @click="GoReservation(sell_item.available, item_id, index)" v-for="(sell_item,index) in items[item_id].sell" :key="index">
                                            <div class="p-1">
                                                <h5 class="mb-1">{{sell_item.title}}권</h5>
                                                <small class="text-sub">구매당일 00:00~23:59 사이 주차 가능</small>
                                            </div>
                                            <div class="p-1 text-end">
                                                <h5 class="fw-bold mb-1">{{sell_item.price.toLocaleString()}}원</h5>
                                                <div v-if="sell_item.available">
                                                    구매하기 <i class="fal fa-chevron-circle-right"></i>
                                                </div>
                                                <div v-if="!sell_item.available">
                                                    매진
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="bg-body mb-2">
                            <ul>
                                <li class="h6 flex-start-center p-3 pb-2">
                                    <i class="fal fa-map-marker-alt fs-px-18 text-gray-relative-500 me-3"></i>
                                    <span>{{items[item_id].address}}</span>
                                </li>
                                <li class="h6 flex-between-center p-3">
                                    <div class="flex-start-center">
                                        <i class="fal fa-phone-alt fs-px-18 text-gray-relative-500 me-3"></i>
                                        <span>{{items[item_id].contact}}</span>
                                    </div>
                                    <a :href="`tel:${items[item_id].contact}`" class="text-primary ms-2 flex-center-center"><span>전화하기</span> <i class="fal fa-chevron-right small ms-1"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="p-3 bg-body mb-2">
                            <h6 class="pb-4">운영시간</h6>
                            <div class="flex-between-center pb-3">
                                <span class="text-gray-relative-600 small">평일</span>
                                <span class="h5">
                                    <small class="text-gray-relative-500 me-2"></small>
                                    <span>00:00 ~ 24:00</span>
                                </span>
                            </div>
                            <div class="flex-between-center pb-3">
                                <span class="text-gray-relative-600 small">토요일</span>
                                <span class="h5">
                                    <small class="text-gray-relative-500 me-2"></small>
                                    <span>00:00 ~ 24:00</span>
                                </span>
                            </div>
                            <div class="flex-between-center pb-3">
                                <span class="text-gray-relative-600 small">공휴일</span>
                                <span class="h5">
                                    <small class="text-gray-relative-500 me-2"></small>
                                    <span>00:00 ~ 24:00</span>
                                </span>
                            </div>
                        </div>
                        <div class="p-3 bg-body mb-2">
                            <h6 class="pb-4">주차요금</h6>
                            <div class="flex-between-center pb-3">
                                <span class="text-gray-relative-600 small">초기무료</span>
                                <span class="h5">
                                    <small class="text-gray-relative-500 me-2"></small>
                                    <span>{{items[item_id].parking_fee.initial_free}}</span>
                                </span>
                            </div>
                            <div class="flex-between-center pb-3">
                                <span class="text-gray-relative-600 small">기본요금</span>
                                <span class="h5">
                                    <small class="text-gray-relative-500 me-2">1시간</small>
                                    <span>{{items[item_id].parking_fee.hour.toLocaleString()}}원</span>
                                </span>
                            </div>
                            <div class="flex-between-center pb-3">
                                <span class="text-gray-relative-600 small">추가요금</span>
                                <span class="h5">
                                    <small class="text-gray-relative-500 me-2">15분당</small>
                                    <span>{{items[item_id].parking_fee.min_15.toLocaleString()}}원</span>
                                </span>
                            </div>
                            <div class="flex-between-center pb-3">
                                <span class="text-gray-relative-600 small">일 주차 요금</span>
                                <span class="h5">
                                    <small class="text-gray-relative-500 me-2"></small>
                                    <span>{{items[item_id].parking_fee.day.toLocaleString()}}원</span>
                                </span>
                            </div>
                            <div class="flex-between-center pb-3">
                                <span class="text-gray-relative-600 small">월 주차 요금</span>
                                <span class="h5">
                                    <small class="text-gray-relative-500 me-2"></small>
                                    <span>{{items[item_id].parking_fee.month.toLocaleString()}}원</span>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="p-3 bg-body">
                            <h6 class="pb-4">기타정보</h6>
                            <ul class="flex-start-center gap-2">
                                <li><span class="rounded-pill p-2 px-3 small bg-gray-relative-200 text-body">카드결제</span></li>
                                <li><span class="rounded-pill p-2 px-3 small bg-gray-relative-200 text-body">단일주차</span></li>
                            </ul>
                        </div> -->
                        <div class="p-3 lh-1">
                            <small class="text-gray-relative-500">
                                <!-- <i class="fal fa-pen"></i>
                                정보 수정 요청 -->
                                안내하는 정보는 현장의 최신 정보와 차이가 있을 수 있습니다. 현장 정보와 일치하지 않아 발생한 피해는 마이파킹이 책임지거나 보상하지 않습니다.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require('axios');

export default {
    data(){
        return{
            items: [
                {
                    id: 0,
                    img: require('@/assets/img/sample_park.jpg'),
                    type: '민영',
                    title: '청담빌딩 주차장',
                    lot: 190,
                    lng: 127.0451749,
                    lat: 37.518136,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: true,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 도산대로 524',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 1,
                    img: require('@/assets/img/inhee_parking.png'),
                    type: '민영',
                    title: '인희빌딩 주차장',
                    lot: 190,
                    lng: 127.051588,
                    lat: 37.5243529,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울특별시 송파구 올림픽로 300',
                    contact: '012)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 2,
                    img: require('@/assets/img/sample_park2.jpg'),
                    type: '민영',
                    title: '소니드빌딩 주차장',
                    lot:190,
                    lng : 127.0538529,
                    lat : 37.5259066,
                    sell: [
                        {
                            available: true,
                            title: '평일 1시간',
                            price: 5000
                        },
                        {
                            available: true,
                            title: '평일 2시간',
                            price: 8000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 5000,
                        min_15: 2000,
                        day: 50000,
                        month: 300000
                    },

                    address: '서울 강남구 도산대로 549',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
                {
                    id: 3,
                    img: require('@/assets/img/sample_park3.jpg'),
                    type: '민영',
                    title: '호텔 리베라 주차장',
                    lot:190,
                    lng : 127.0541241,
                    lat : 37.5238934,
                    sell: [
                        {
                            available: false,
                            title: '평일 1시간',
                            price: 4000
                        },
                        {
                            available: false,
                            title: '평일 2시간',
                            price: 7000
                        },
                        {
                            available: false,
                            title: '평일 12시간',
                            price: 40000
                        },
                    ],
                    operating_time: [
                        {
                            type: 0,
                            time: '평일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 1,
                            time: '토요일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                        {
                            type: 2,
                            time: '공휴일',
                            start_time: '00:00',
                            end_time: '24:00'
                        },
                    ],
                    parking_fee: {
                        initial_free: '없음',
                        hour: 4000,
                        min_15: 1500,
                        day: 40000,
                        month: 250000
                    },

                    address: '서울 강남구 영동대로 737',
                    contact: '02)345-6789',
                    bookmark: false,
                    affiliated: true,
                },
            ],

            item_id: 0,

            // front ui
			pp: false,
			off: false,
            timeout_id: null,

            isDragging: false,
            startY: 0,
            deltaY: 0,
            windowHeight: 0,
            contentHeight: 300,
            popupContentDiv: null,

            fullPagIng: false,
            fullPaged: false,
            tinyPagIng: false,
            tinyPaged: true,

            isClicked: false,

            no_animation: false,
            lat : '',
            lng:'',
        }
    },
    computed:{
        // item_id(){
        //     return this.$route.params.id;
        // }
    },
    mounted(){

        this.windowHeight = window.innerHeight;
        this.popupContentDiv = document.querySelector(".content");

        // addEventListener("mousemove", this.HandleDrag);
        // addEventListener("mouseup", this.EndDrag);
        addEventListener("touchmove", this.HandleDrag);
        addEventListener("touchend", this.EndDrag);

        navigator.geolocation.getCurrentPosition(_position => {
            this.lat = _position.coords.latitude
            this.lng = _position.coords.longitude
        })
    },
    destroyed(){
        // removeEventListener("mousemove", this.HandleDrag);
        // removeEventListener("mouseup", this.EndDrag);
        removeEventListener("touchmove", this.HandleDrag);
        removeEventListener("touchend", this.EndDrag);
    },
	methods: {
        GotoDriving(){

            // let config = {
            //     method: 'get',
            //     maxBodyLength: Infinity,
            //     url: 'https://naveropenapi.apigw.ntruss.com/map-direction/v1/driving?option=trafast&start=37.524368804519405,127.0515686654505&goal=37.50741134688475,127.0621149566286',
            //     headers: { 
            //         'X-NCP-APIGW-API-KEY-ID': '3wbgw5bfa1', 
            //         'X-NCP-APIGW-API-KEY': 'IljS5Lv6F1QlOZ7uEArPBzP6UEiuzxtLQ7EgUX7a', 
            //         'Content-Type': 'application/json'
            //     },
            // };

            // axios.request(config).then((response) => {
            //     console.log(JSON.stringify(response.data));
            // }).catch((error) => {
            //     console.log(error);
            // });
            // alert("?")
            location.href= 'nmap://route/car?dlat=37.5209436&dlng=127.1230074&appname=map.wonsungglobal.com'
        },

        GoReservation(available, item_id, index){
            if(available){
                this.$router.push(`/reservation/${item_id}/${index}`)
            }
        },
        // front ui
        pp_off(){
            if(this.no_animation){
                this.pp = false;
                return;
            };

            this.off = true;


            if (this.timeout_id) {
                clearTimeout(this.timeout_id);
            }

            this.timeout_id = setTimeout(() => {
                this.pp = false;
                this.off = false;
                if( this.$refs.basic_pp_body ){
                    this.$refs.basic_pp_body.scrollTop = 0;}
                this.timeout_id = null;
                
                this.fullPaged = false;
                this.fullPagIng = false;
                this.tinyPaging = false;
                this.tinyPaged = true;

                this.popupContentDiv.style.top = "";
                this.contentHeight = 300;

            }, 200);
        },
        StartDrag($event) {
            this.isDragging = true;
            this.deltaY = 0;

            // this.startY = $event.clientY;
            if($event.touches && $event.touches[0]) {
                this.startY = $event.touches[0].clientY;
                this.contentHeight = this.windowHeight - this.popupContentDiv.offsetTop;
            }
        },
        HandleDrag($event) {
            if (!this.isDragging || this.fullPagIng || this.tinyPagIng) {
                this.isDragging = false;
                return;
            }
            // this.deltaY = $event.clientY - this.startY;
            this.deltaY = $event.touches[0].clientY - this.startY;

            if( this.fullPaged && this.deltaY < 0 ){
                return;
            }

            if( this.fullPaged && this.deltaY >= 0 ){
                if( this.popupContentDiv.scrollTop !== 0 ){
                    this.deltaY = 0;
                    return;
                }else if( this.no_animation ){
                    this.deltaY = 0;
                    return;
                }
            }

            this.popupContentDiv.style.top = this.windowHeight - (this.contentHeight - this.deltaY)+"px";

            if( this.deltaY < -100 ){
                this.FullPaging();
                return;

            }else if( this.fullPaged && this.deltaY > 100 ){
                this.TinyPaging();
                return;

            }else if( this.tinyPaged && this.deltaY > 50 ){
                this.pp_off();
                return;
            }
        },
        EndDrag() {
            if (!this.isDragging) return;
            if (this.deltaY == 0) return;

            this.isDragging = false;
            this.deltaY == 0;

            if( this.deltaY < -60 ){
                this.FullPaging();
                return;

            }else if( this.fullPaged && this.deltaY > 60 ){
                this.TinyPaging();
                return;

            }else if( this.fullPaged && this.deltaY <= 60 ){
                this.FullPaging();
                return;

            }else{
                this.TinyPaging();
            }
        },

        FullPaging(){
            this.isDragging = false;
            this.popupContentDiv.style.top = "";
            this.fullPagIng = true;
            this.tinyPagIng = false;
            this.tinyPaged = false;
            setTimeout(() => this.FullPaged(), 100);

        },
        FullPaged(){
            this.isDragging = false;
            this.fullPagIng = false;
            this.fullPaged = true;
            this.tinyPagIng = false;
            this.tinyPaged = false;

        },

        TinyPaging(){
            this.isDragging = false;
            this.popupContentDiv.style.top = "";
            this.tinyPagIng = true;
            this.tinyPaged = false;
            this.fullPagIng = false;
            this.fullPaged = false;
            // setTimeout(()=>this.TinyPaged(),200);
            this.TinyPaged()
        },
        TinyPaged(){
            if(this.no_animation){
                this.pp = false;
                return;
            };

            this.isDragging = false;
            this.tinyPagIng = false;
            this.tinyPaged = true;
            this.fullPagIng = false;
            this.fullPaged = false;
        },

	},
}
</script>
<style lang="scss" scoped>
    .content{
        max-height: 100vh;
        min-height: 100vh;

        .body{
            max-height: 100%;
        }
    }

    .bottom_up{
        height: 0;

        &.no_animation{
            .content{
                animation: none;
            }
        }

        .content{
            user-select: none;
            animation: slideUp 0.3s;
            width: calc(100% + 2px);
            margin-left: -1px;
        }

        &.off{
            .dimm{
                animation: fadeInOff 0.2s;
                animation-fill-mode: forwards;
            }
            .content{
                animation: slideUpOff 0.3s;
                animation-fill-mode: forwards;
            }
        }
    }

    .full_paging{
        top: 0 ;
        transition: all 0.2s ease-in-out;
    }

    .tiny_paging{
        top: calc(100vh - 300px) ;
        transition: all 0.2s ease-in-out;
    }
    .full_paged{
        top: 0 ;
        border-radius: 0;
    }
    .full_paged:not(.dragging){
        transition: all 0.2s ease-in-out;
    }
    .tiny_paged{
        top: calc(100vh - 300px);
    }
    .tiny_paged:not(.dragging){
        transition: all 0.2s ease-in-out;
    }
    
    @keyframes slideUp{
        from { top: 100%; }
        to { top: calc(100vh - 300px); }
    }
    @keyframes slideUpOff{
        to { top: 100%; }
    }
    
</style>