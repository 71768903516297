<template>
    <div class="add_account">
        <Header :title="title" :goPath="'/'"></Header>
        <div class="container">
            <div class="pb-5">
                <ul v-if="list.length!=0">
                    <li v-for="(item,index) in list" :key="index" class="mb-2" @click="$refs.InfoPopup.no_animation = true; $refs.InfoPopup.FullPaged(); $refs.InfoPopup.item_id = 0; $refs.InfoPopup.pp = true;">
                        <div class="bg-img w-100 rounded-500 p-3 h-px-110 d-flex flex-column justify-content-end" :style="`background-image: url(${item.bg})`">
                            <div>
                                <h5 class="fw-bold text-white">{{item.name}}</h5>
                                <small class="opacity-75 text-white fs-px-12" v-if="item.status==0">주차장 확인 중에 있습니다.</small>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="py-5 my-5 text-gray-relative-500 text-center" v-else>
                    등록된 주차장이 없습니다.
                </div>
            </div>
        </div>
        <div class="d-flex footer_btn bg-white">
            <router-link to="/mypage/add-parking" class="btn bg-primary text-white w-100 thick">주차장 등록하기</router-link>
        </div>

        <InfoPopup ref="InfoPopup" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import InfoPopup from '@/components/reservation/InfoPopup.vue'

export default {
    components:{
        Header,InfoPopup
    },
    data(){
        return{
            title: "내 공유 주차장",

            list: [
                {
                    name: '마이 주차장',
                    bg: require("@/assets/img/parking_logo_bg.png"),
                    status: 0
                },
                {
                    name: '카카오 T 메인프라자 주차장',
                    bg: require("@/assets/img/parking_logo_bg.png"),
                    status: 1
                },
            ],

            infoPp: [{on:false}, {on:false}],

        }
    },
    created(){
    },
    methods:{
    },
}
</script>
