<template>
    <div class="add_account">
        <div class="container header">
            <div class="d-flex align-items-center justify-content-between ps-0">
                <div @click="Back()">
                    <i class="fal fa-chevron-left close"></i>
                </div>
                <p class="title">{{title}}</p>
            </div>
        </div>

        <div class="container position-relative" v-if="step==0">
            <div class="mh-vh-100">
                <div class="text-center position-absolute top-50 start-50 translate-middle w-100">
                    <div class="pb-5">
                        <img src="@/assets/img/parking.svg" width="210">
                    </div>
                    <h5>
                        빈 주차공간만 있으면<br>
                        누구나 수익을 올릴 수 있습니다
                    </h5>
                </div>
            </div>
            <div class="d-flex footer_btn">
                <div class="btn bg-primary text-white w-100 thick" @click="step=1">주차장 등록하기</div>
            </div>
        </div>


        <div class="container" v-if="step==1">
            <div class="position-relative mh-vh-100 pb-5">
                <div class="text-center">
                    <h5 class="py-px-80 fw-bold">
                        공유할 주차공간의 사업자 유형을<br>선택해 주세요.
                    </h5>
                    <div>
                        <button class="w-100 btn py-3 mb-2 h5" :class="{'btn-primary':buisnessman==0, 'btn-outline-primary':buisnessman!=0}" @click="buisnessman=0">법인 사업자 주차장</button>
                        <button class="w-100 btn py-3 h5" :class="{'btn-primary':buisnessman==1, 'btn-outline-primary':buisnessman!=1}" @click="buisnessman=1">개인 사업자 주차장</button>
                    </div>
                </div>
            </div>
            <div class="d-flex footer_btn">
                <div class="btn bg-primary text-white w-100 thick" @click="step=2" :class="{'disabled':buisnessman==null}">다음</div>
            </div>
        </div>


        <div class="container" v-if="step==2">
            <div class="position-relative mh-vh-100 pb-5">
                <div class="text-center">
                    <h5 class="py-px-80 fw-bold">
                        희망하는 운영방식을 선택해 주세요.
                    </h5>
                    <div>
                        <button class="w-100 btn py-3 mb-2 h5" :class="{'btn-primary':operate==0, 'btn-outline-primary':operate!=0}" @click="operate=0">
                            시간제 운영<br>
                            <small class="fs-px-12">30분 단위 요금으로 운영합니다.</small>
                        </button>
                        <button class="w-100 btn py-3 h5" :class="{'btn-primary':operate==1, 'btn-outline-primary':operate!=1}" @click="operate=1">
                            월주차 운영<br>
                            <small class="fs-px-12">비워두는 시간이 규칙적인 경우만 가능합니다.</small>
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex footer_btn">
                <div class="btn bg-primary text-white w-100 thick" @click="step=3" :class="{'disabled':operate==null}">다음</div>
            </div>
        </div>


        <div class="container" v-if="step==3">
            <div class="position-relative mh-vh-100 pb-5">
                <div class="text-center">
                    <h5 class="py-px-80 fw-bold">
                        공유 조건을 만족하는지 확인해 주세요.
                    </h5>
                    <div>
                        <p class="mb-3">외부인이 주차 가능한 곳인가요?</p>
                        <div class="d-flex jusfy-content-between">
                            <button class="w-50 btn py-3 h5 me-2" :class="{'btn-primary':available_outsider==0, 'btn-outline-primary':available_outsider!=0}" @click="available_outsider=0">
                                외부인 주차 가능
                            </button>
                            <button class="w-50 btn py-3 h5" :class="{'btn-primary':available_outsider==1, 'btn-outline-primary':available_outsider!=1}" @click="available_outsider=1">
                                아니요
                            </button>
                        </div>

                        <p class="mt-5 pt-3 mb-3">지정 주차면이 있나요?</p>
                        <div class="d-flex jusfy-content-between">
                            <button class="w-50 btn py-3 h5 me-2" :class="{'btn-primary':designated_area==0, 'btn-outline-primary':designated_area!=0}" @click="designated_area=0">
                                지정 주차면
                            </button>
                            <button class="w-50 btn py-3 h5" :class="{'btn-primary':designated_area==1, 'btn-outline-primary':designated_area!=1}" @click="designated_area=1">
                                아니요
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex footer_btn">
                <div class="btn bg-primary text-white w-100 thick" @click="ChkShare()" :class="{'disabled':available_outsider==null || designated_area==null}">다음</div>
            </div>
            
            <div class="basic_pp bottom_up" v-if="share_chk_pp">
                <div class="dimm bg-body-i bg-opacity-50" @click="share_chk_pp = false"></div>
                <div class="content bg-body">
                    <div class="position-sticky pb-4 mt-2">
                        <h5 class="text-truncate px-3 fw-bold">다음 두 가지 사진을 준비해주세요.</h5>
                    </div>
                    <div class="body overflow-auto">
                        <ol class="share_pp_list">
                            <li class="mb-2">
                                주차환경 파악 가능한 사진
                            </li>
                            <li>
                                토지, 건물 관계 입증 서류<br>(예:토지매매서, 임대계약서)
                            </li>
                        </ol>
                    </div>
                    <div class="mt-2">
                        <div class="d-flex justyfy-content-between">
                            <button class="w-100 btn btn-light me-2" @click="share_chk_pp = false">닫기</button>
                            <button class="w-100 btn btn-primary" @click="step=4; share_chk_pp=false">확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="container" v-if="step==4">
            <div class="position-relative mh-vh-100 pb-5">
                <div class="text-center">
                    <div class="text-start">
                        <div class="mb-3">
                            <label>주차공간 이름</label>
                            <input type="text" v-model="name" class="form-control" placeholder="주차공간 이름">
                        </div>
                        <div class="mb-3">
                            <label>주소</label>
                            <input type="text" v-model="address" class="form-control" placeholder="ex) 경기도 하남시 미사대로 520">
                        </div>
                        <div class="mb-3">
                            <label>주차 면 수</label>
                            <input type="number" v-model="lot" class="form-control" placeholder="면">
                        </div>
                        <div class="mb-3">
                            <label>30분당 주차 요금</label>
                            <input type="number" v-model="price" class="form-control" placeholder="원">
                        </div>
                        <div class="mb-3">
                            <label>주차장 설명(선택)</label>
                            <textarea rows="3" v-model="desc" class="form-control" placeholder="주차공간을 찾기 쉽게 설명해 주시면 더 많은 운전자가 주차합니다."></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex footer_btn">
                <div class="btn bg-primary text-white w-100 thick" @click="Regist()" :class="{'disabled':ChkInput}">등록</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            title: '내 주차장 등록',
            step: 0,

            share_chk_pp: false,

            buisnessman: null,
            operate: null,

            available_outsider: null,
            designated_area: null,

            name: "",
            address: "",
            lot: "",
            price: "",
            desc: "",
        }
    },
    created(){
    },
    computed:{
        ChkInput(){
            if( this.name==""||this.address==""||this.lot==""||this.price=="" ) return true
            else return false
        }
    },
    methods:{
        Back(){
            if( this.step == 0 ){
                this.$router.go(-1);
            }else{
                this.step -= 1
            }
        },

        ChkShare(){
            if( this.available_outsider == 1 || this.designated_area == 1 ){
                this.$fire({
                    title: "공유 등록 불가합니다.",
                    text: "공유 조건을 모두 만족하는 경우에만 등록 가능합니다.",
                    confirmButtonText: "확인",
                })

            }else{
                this.share_chk_pp = true
            }
        },

        Regist(){
            this.$fire({
                title: "내 주차장 등록이 완료되었습니다.",
                text: "내 주차장은 최대 하루 정도가 소요될 수 있습니다.",
                confirmButtonText: "확인",
            }).then(result => { 
                this.$router.push("/mypage/my-parking");
             });
        }
    }
}
</script>
<style lang="scss" scoped>
    .header{
        max-width: 768px;
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 58px;
        background-color: #fff;
        z-index: 99;
        width: 100%;

        .title{
            position: absolute;
            font-size: 1em;
            left: 50%;
            transform: translateX(-50%);
            font-weight: bolder;
            white-space: nowrap;
        }
        >div{
            width: 100%;
            height: 100%;
            padding: 0 .5em;
        }
        i{
            font-size: 1.5em;   
        }
    }
    i.close{
        font-size: 1.2em;
        margin-right: auto;
    }

    .py-px-80{
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .container{
        .mh-vh-100{
            min-height: calc(100vh - 120px);
        }
    }

    .btn.bg-primary.disabled{
        background-color: #ccc !important;
    }

    .share_pp_list li{
        list-style: decimal;
    }
</style>